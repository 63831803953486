#kiosk-asset-tool {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.main-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.tool-section {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.left-column {
    flex: 1;
}

.main-space {
    width: 80%;
    flex: 2;
    display: flex;
    flex-direction: column;
}

.right-column {
    flex: 1;
}

.scrollable-list {
    overflow-y: auto;
    height: 100%;
}

.section-heading {
    font-size: large;
    text-align: center;
}

.kiosk-pages-list-group {
    overflow-y: auto;
}

.list-group-item-heading {
    text-align: center;
    font-size: larger;
}

.kiosk-page-list-item {
    text-align: center;
}

.main-space-image-div {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.main-space-image {
    border: 2px solid black;
}

.main-space-fields {
    flex: 2;
    margin: 5px;
}

.field-row {
    margin: 5px;
}

.review-updates-button-div {
    margin-top: 20px;
}

.deploy-button-div {
    margin: 5px;
    text-align: center;
}

.deploy-button {
    width: 400px;
}

.deployment-container {
    display: flex;
    flex-direction: column;
}

.deployment-change {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.deployment-label {
    font-size: 32px;
}

.old-deployment-item {
    font-size: larger;
    text-decoration: line-through;
}

.new-deployment-item {
    font-size: larger;
}

.deployment-images {
    display: flex;
    align-items: center;
    justify-content: center;
}

.deployment-image img {
    width: 100%;
}

.grayscale {
    filter: grayscale(100);
}

.deployment-screen-image{
    max-height: 300px;
    height: 100%;
    border: 2px solid black;
}

.deploy-button {

}